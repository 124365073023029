import styled from '@emotion/styled'

interface Props {
  noHover?: boolean
}
const ProjectThumb = styled('div')`
  height: 92vw;
  width: 92vw;
  margin: 2vw 4vw;
  overflow: hidden;
  display: flex;
  ${({ noHover = false }: Props) =>
    !noHover &&
    `&:hover {
    transition: opacity 0.4s;
    cursor: pointer;
    opacity: 0.8;
  }`} @media (min-width: 500px) {
    height: 300px;
    width: 300px;
    margin: 10px;
  }
`

export default ProjectThumb
