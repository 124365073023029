/** @jsx jsx */
import { css, jsx } from '@emotion/react' // jsx has to be in scope here for some reason???
import * as React from 'react'

import ProjectSection from '../components/ProjectSection'
import AboutSection from '../components/AboutSection'
import IndexLayout from '../layouts'
import Parallax from '../components/Parallax'

import { graphql, navigate } from 'gatsby'
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image'
import { AllNodes } from '../lib'

interface Props {
  data: {
    allContentfulHeroImage: AllNodes<{
      title: string
      project?: {
        path: string
      }
      image: {
        data: IGatsbyImageData
      }
      texture?: {
        data: IGatsbyImageData
      }
    }>
  }
}

const IndexPage: React.FC<Props> = ({ data }) => (
  <>
    <IndexLayout>
      <>
        {data.allContentfulHeroImage.nodes.map((hero) => (
          <React.Fragment key={hero.title}>
            <span onClick={() => hero.project && navigate(hero.project.path)}>
              <GatsbyImage
                image={getImage(hero.image.data)!}
                alt={hero.title}
                css={css`
                  background-color: white;
                  height: 100vh;
                  cursor: pointer;
                  width: 100vw;
                `}
              />
            </span>
            <Parallax src={hero.texture && getImage(hero.texture.data)?.images.fallback?.src} />
          </React.Fragment>
        ))}
      </>
      <ProjectSection section="Design" />
      <ProjectSection section="Illustration" />
      <AboutSection />
    </IndexLayout>
  </>
)

export default IndexPage
export const query = graphql`
  {
    allContentfulHeroImage(sort: { fields: position, order: ASC }) {
      nodes {
        title
        image {
          data: gatsbyImageData
        }
        texture {
          data: gatsbyImageData(width: 3000)
        }
        project {
          path
        }
      }
    }
  }
`
