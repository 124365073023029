import styled from '@emotion/styled'

interface IProps {
  offset: number | string
}

const Anchor = styled('a')`
  display: block;
  height: 0;
  width: 0;
  position: absolute;
  top: ${(props: IProps) => props.offset};
  visibility: hidden;
`

export default Anchor
