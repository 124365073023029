import styled from '@emotion/styled'

const ProjectGrid = styled('div')`
  display: flex;
  grid-area: project-grid;
  margin: 5vh 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  padding: auto 10vw;
  justify-content: center;
  align-content: center;
  max-width: 1000px;
  @media (min-width: 600px) {
    margin: 10vh auto;
  }
`

export default ProjectGrid
