import styled from '@emotion/styled'

const HEIGHT = 200
const MOBILE_HEIGHT = 100
const PATH = `
  0 56%, 10% 100%, 19% 59%, 28% 100%, 37% 57%, 45% 100%, 54% 57%, 63% 100%,
  71% 61%, 81% 100%, 90% 60%, 100% 100%, 100% 43%, 90% 0, 81% 40%, 72% 0,
  63% 41%, 55% 0, 46% 40%, 37% 1%, 27% 39%, 19% 0, 10% 41%, 0 0
`

const MOBILE_PATH = `
  0% 100%, 11% 71%, 24% 100%, 37% 69%, 51% 100%, 63% 70%, 75% 100%,
  86% 72%, 100% 100%, 100% 30%, 87% 0, 75% 28%, 62% 0, 50% 29%, 36% 0,
  23% 28%, 11% 0, 0 28%
`

interface IProps {
  src?: string
}

const Parallax = styled('div')`
  ${(props: IProps) => (props.src ? `background-image: url(${props.src});` : 'background-color: #fffbf8;')};
  background-position: cover;
  background-repeat: round;
  background-size: auto 150vh;
  clip-path: polygon(${PATH});
  float: left;
  height: ${HEIGHT}px;
  margin: -${HEIGHT / 2}px 0;
  width: 100vw;
  z-index: 99;
  background-attachment: fixed;
  @media (max-width: 600px) {
    background-attachment: scroll;
    background-repeat: no-repeat;
    height: ${MOBILE_HEIGHT}px;
    margin: -${MOBILE_HEIGHT / 2}px 0;
    clip-path: polygon(${MOBILE_PATH});
  }
  @media (min-width: 600px) {
    display: block;
  }
`

export default Parallax
