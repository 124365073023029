/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import React from 'react'
import styled from '@emotion/styled'
import { graphql, navigate } from 'gatsby'
import ProjectGrid from './ProjectGrid'
import ProjectThumb from './ProjectThumb'
import Anchor from './Anchor'
import { AllNodes, IProjectCellProps, IProjectNode } from '../lib'
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image'
import { useStaticQuery } from 'gatsby'

const Wrapper = styled('div')`
  display: grid;
  position: relative;
  grid-template-areas:
    'title'
    'project-grid';
  background-color: #fffbf8;
  margin-top: 150px;
  &:nth-last-child(2) {
    margin-top: 100px;
  }
`

const Title = styled('h1')`
  grid-area: title;
  text-align: center;
  vertical-align: text-bottom;
`

interface QueryResult {
  allContentfulProject: AllNodes<IProjectNode>
}

const ProjectCell: React.FC<IProjectCellProps> = ({ project }, idx) => (
  <div
    onClick={() => navigate(project.path)}
    css={css`
      h4 {
        opacity: 0;
        color: ghostwhite;
        z-index: 99999;
        font-size: 2.25rem;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 9999;
        margin: 0;
        text-align: center;
        text-shadow: 2px 2px 8px darkgrey;
        transition: opacity 0.5s;
        cursor: pointer;
        position: absolute;
      }
      position: relative;
      &:hover {
        & > div {
          transition: filter 0.4s ease;
          cursor: pointer;
          filter: blur(0.25rem);
        }
        h4 {
          opacity: 1 !important;
        }
      }
    `}
  >
    <h4>{project.title}</h4>
    <ProjectThumb key={idx} noHover>
      <GatsbyImage
        image={getImage(project.thumbnail) as IGatsbyImageData}
        alt="foo"
        css={css`
          height: 100%;
          width: 100%;
          h4 {
            display: none;
            :hover {
              display: initial;
            }
          }
        `}
      />
    </ProjectThumb>
  </div>
)

const ProjectSection: React.FC<Pick<IProjectNode, 'section'>> = ({ section }) => {
  const data = useStaticQuery<QueryResult>(graphql`
    query ProjectSectionQuery {
      allContentfulProject {
        nodes {
          title
          path
          section
          description {
            description
          }
          thumbnail {
            gatsbyImageData(
              # width: 200
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
          images {
            gatsbyImageData
          }
        }
      }
    }
  `)

  return (
    <Wrapper>
      <Anchor offset="-70px" id={section} />
      <Title>{section}</Title>
      <ProjectGrid>
        {data.allContentfulProject.nodes
          .filter((project) => project.section === section)
          .map((node, idx) => (
            <ProjectCell project={node} key={idx} />
          ))}
      </ProjectGrid>
    </Wrapper>
  )
}

export default ProjectSection
