import React from 'react'
import styled from '@emotion/styled'
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image'
import { graphql, useStaticQuery } from 'gatsby'
import Anchor from '../Anchor'

const Wrapper = styled('section')`
  display: flex;
  flex-direction: column;
  height: auto;
  min-height: 90vh;
  position: relative;
  text-align: center;
  align-items: center;
  justify-content: center;
  @media (min-width: 600px) {
    min-height: 90vh;
    flex-direction: row;
  }
`

const ImageWrapper = styled('div')`
  width: 100%;
  @media (min-width: 345px) {
    width: 345px;
  }
  @media (min-width: 600px) {
    width: 500px;
    margin-right: 40px;
  }
`

interface QueryData {
  contentfulBio: {
    title: string
    picture: IGatsbyImageData
    description: {
      description: string
    }
  }
}

const Text = styled('div')`
  height: fit-content;
  padding: 20px 20px;
  width: auto;
  h1 {
    text-transform: uppercase;
  }
  p {
    margin: 32px auto;
  }
  @media (min-width: 600px) {
    padding-left: 0;
    margin-left: 40px;
    width: 500px;
    text-align: left;
  }
`

const AboutSection: React.FC = () => {
  const data = useStaticQuery<QueryData>(graphql`
    query AboutSectionQuery {
      contentfulBio {
        title
        description {
          description
        }
        picture {
            gatsbyImageData(
              # width: 200
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
      }
    }
  `)
  return (

    <Wrapper>
      <Anchor offset="calc(-1 * (2rem + 110px))" id="about" />
      <ImageWrapper>
        <GatsbyImage image={getImage(data.contentfulBio.picture) as IGatsbyImageData} alt="foo" />
      </ImageWrapper>
      <Text>
        <h1>{data.contentfulBio.title}</h1>
        <p>{data.contentfulBio.description.description}</p>
      </Text>
    </Wrapper>
  )
}

export default AboutSection
